// src/assets/scss/style.scss
@import 'main/typography';
@import 'main/modal';
@import 'main/dark';
@import 'main/404';
@import 'main/style';
@import 'node_modules/react-modal-video/scss/modal-video.scss';
@tailwind base;
@tailwind components;
@tailwind utilities;
