.error_page {
  h1,
  h2 {
    font-weight: 900;
    font-size: 125px;
    line-height: 1;
    letter-spacing: 5px;
    @media screen and (max-width: 768px) {
      font-size: 80px;
    }
  }
  .hero {
    width: 100%;
    height: 100vh;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
  .bg-image {
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
  }
  .content {
    p {
      color: #000;
      margin-bottom: 35px;
      margin-top: 15px;
      @media screen and (max-width: 768px) {
        margin-bottom: 30px;
        font-size: 18px;
      }
    }
  }
}
